@import "../../scss/_variables";

.framne {
  background-color: $Primary;
  position: relative;
  display: flex;
  min-height: 250px;
  margin-bottom: 2em;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  svg {
    height: 20%;
    color: whitesmoke;
    pointer-events: none;
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    min-height: 350px;
  }
}

.dataText {
  padding: 1em;
  height: 100%;
  background: whitesmoke;

  position: absolute;
  width: 100%;
  transition: transform 0.2s ease-out;
  transition: all 0.5s ease;
  z-index: 10;
  :first-child {
    pointer-events: none;
  }

  hr {
    margin-top: 0.5em;
  }
  ul {
    pointer-events: none;
    li {
      pointer-events: none;
      display: inline-block;
      padding-right: 1em;
      padding-left: 1em;
      border-style: solid;
      border-color: $Secondary;
      border-radius: 8px;
      margin-right: 1em;
      margin-bottom: 0.5em;
      border-width: 2px;
      font-weight: 600;
      color: darkgray;
      font-size: 12px;
    }
  }
  div {
    pointer-events: none !important;
  }
  :first-child {
    pointer-events: none !important;
  }
  :last-child {
    pointer-events: none !important;
  }

  // Breakpoint para sm y mayores
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding: 2em;
  }
}

.iconDownload {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-content: center;
  pointer-events: none !important;
  h5 {
    color: whitesmoke;
    text-align: center;
    padding: 0px !important;
    margin-left: 1em;
  }
}

.tipo {
  pointer-events: none;
  display: inline-block;
  padding: 0.5em 1em 0.5em 1em;
  border-style: solid;
  border-color: $Primary;
  background-color: $Primary;
  border-radius: 8px;
  margin-right: 1em;
  border-width: 2px;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5em;
  svg {
    height: 100%;
    margin-right: 1em;
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 15px;
    width: 100%;
    height: 4em;
    padding: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    svg {
      height: 100%;
    }
  }
}

.pointerEventNone {
  pointer-events: visibleStroke !important;
  :first-child {
    pointer-events: visibleFill !important;
  }
  :last-child {
    pointer-events: visibleStroke !important;
  }
}

.tituloCiclos {
  padding: 0px !important;
}
