@import "../scss/_variables";
$round: 5px;

.ciclo {
  pointer-events: none;
  display: inline-block;
  padding-right: 1em;
  padding-left: 1em;
  border-style: solid;
  border-color: $Secondary;
  border-radius: 8px;
  margin-right: 1em;
  border-width: 2px;
  font-weight: 600;
  color: darkgray;
}

.float {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease;
  z-index: 2;
  :first-child {
    
    :hover {
      rotate: initial;
      box-shadow: initial;
      -webkit-box-shadow: initial;
      -moz-box-shadow: initial;
      transform: initial;
    }
  }

  :hover {
    
    transition: all 0.5s ease;
    transform: perspective(30em) rotateX(5deg);
    box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
    
  }
}
