@import "../../scss/_variables";

.cantidadDeObras {
  font-family: "DM Serif Display", serif;
  font-weight: 500 !important;
  font-size: 55px !important;
  letter-spacing: -3px !important;
}
.obrasPara {
  h3 {
    font-style: italic;
    margin-top: 3em;
    text-transform: uppercase;
    font-size: 32px;
    span {
      font-weight: 500;
      font-size: 32px;
      color: $Secondary;
    }
  }

  .obra {
    @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
      padding: 0 12px 0 12px;
    }
  }
}
