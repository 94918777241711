@import "../../scss/_variables";

.page {
  margin: 0px !important;
  padding: 0px !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $Primary-dark;
  }
}

.repertorioPage {
  margin: 0px !important;

  .heather {
    background: $SecondaryGradient;
  }
  .title {
    position: relative;

    :before {
      content: "";
      height: 70%;
      width: 2px;
      background-color: whitesmoke;
      position: absolute;
      left: 0px;
    }
    h2 {
      font-style: italic;
      font-size: 48px !important;
      color: whitesmoke;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    .title {
      ::before {
        content: none;
      }
    }
  }
}
