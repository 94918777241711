@import "../../scss/variables";

$paddingImg: 20px;

.silderContainer {
  position: relative;
  min-height: 54vh;
  padding-left: 20px;
  padding-right: 20px;
}

.itemContainer {
  position: relative;
  width: 100%; /* Ancho deseado del div */
  height: 50vh; /* Altura deseada del div */
  background-image: url("../../assets/images/loading-spinner.webp");
  background-position: center;
  background-size: 5%;
  background-repeat: no-repeat;

  img {
    z-index: 5;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $Round;
  }
}

.overlay {
  position: absolute;
  top: 0;
  padding-right: 3em;
  left: 0%;
  z-index: 10;
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: whitesmoke;

  h1,
  h4 {
    color: whitesmoke !important;
    filter: drop-shadow(2px 2px 4px #000000);
    text-align: center !important;
    margin-right: 3em;
    margin-left: 3em;
    z-index: 8;
  }
  h1 {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0px;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 1em;
  }
}
.gradient {
  min-height: 100%;
  width: 99%;
  display: flex;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.textBg {
  position: absolute;
  top: 0px;
  z-index: 15;
  content: "";
  min-width: 100%;
  min-height: 100%;
}

.itemContainer {
  padding: $paddingImg;
}

.slick-next {
  background-color: $Primary-dark !important;
}

.estreno {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0em;
  text-align: center;
  width: 100%;
  pointer-events: none;
}

.estrenoText {
  height: 100px;
  position: absolute;
  top: 0%;
  right: 0px;
  padding: $paddingImg;
  font-size: 2em;
  font-weight: 800;
  line-height: 1.1em;
  display: flex;
  justify-content: flex-end;

  .containerText {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    width: 100%;

    p {
      position: absolute;
      color: white;
      transform: translate(130px, 100px) rotate(45deg);
      top: 0px;
      right: 0px;
      background-color: red;
      padding-right: 8em;
      padding-left: 8em;
      padding-top: 0.1em;
      padding-bottom: 0.1em;
      font-size: 24px;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    }
  }
}

@media (max-width: 480px) {
  .mobileMarginCero {
    padding: 0px !important;
  }

  #homeHeroSlider {
    padding: 0px !important ;
  }

  .silderContainer {
    position: relative;
    padding: 0px !important;
    margin: 0px;
    img {
      padding: 0px;
      margin: 0px !important;
    }
  }
  .itemContainer {
    padding: 0 !important;
    height: 85vh; /* Altura deseada del div */
  }
  .overlay {
    text-align: center;
    padding: 0px;
    top: 0%;
    h1,
    h4 {
      margin-right: 1em;
      margin-left: 1em;
    }

    h1 {
      margin-bottom: 1em;
    }

    h4 {
      font-size: 1em;
    }
  }

  .gradient {
    background: transparent;
    width: 100%;
    margin: 0px;
    z-index: 2;
  }
  .estreno {
    width: 100%;
    position: absolute;
    top: -15%;
    right: unset;
    text-align: center;
  }

  .estrenoText {
    display: flex;
    align-content: flex-start;
    position: absolute;
    top: 0%;
    font-size: 1em;
    font-weight: 800;
    line-height: 1.2em;
    width: 100%;
    margin: 0px;
  }

  .containerText {
    margin: 0px;
    margin: -20px;
    p {
      transform: rotate(0deg) !important;
      top: 20% !important;
      left: 0px !important;
      background-color: red !important;
      line-height: 1.2em;
      text-align: left;
      padding: 1em !important;
    }
  }
}

.mobileMarginCero {
  padding: unset;
}
