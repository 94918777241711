@import "../../scss/_variables.scss";
footer {
  background-color: $Primary;
  margin: 0px;
  position: relative;
}
.footerCont {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  padding: 0;
  min-height: 20vh;
  h4 {
    padding: 0px;
    color: white;
  }
}

.socialLinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  a {
    color: white;
    padding-right: 1em;
    :hover {
      color: whitesmoke;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    justify-content: space-between;
    padding: 20px;
  }
}

.contacContainer {
  width: 100%;
  margin: 0px;
  display: flex;
}

.contact {
  bottom: 80px;
  display: flex;
  padding: 2em 0 2em 0;
}

.subFooterContent {
  min-height: 80px;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  color: white;
  background-color: $Primary-darker;
  display: flex;

  span {
    :hover {
      opacity: 0.8;
    }
    a {
      color: white;
      vertical-align: baseline;
    }
  }
  img {
    padding-left: 0.8em;
    fill: white !important;
    height: 1.5em;
  }
}
.copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

.apsis {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-top: 1em;
    justify-content: center;
    padding-bottom: 1em;
  }
}
