@import "../../scss/variables";

.formSection {
  background: $SecondaryGradient;
  border-radius: $Round;

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    border-radius: 0px;
    margin-top: -20px !important;
    margin-bottom: 0px !important;
  }
}

.imageFormContainer {
  display: flex;
  align-items: flex-end;
  img {
    display: flex;
  }
}

.formTitel {
  color: whitesmoke;
}
