@import "../../scss/_variables.scss";
.contactChanel {
  display: flex;
  justify-content: center;
  @media (max-width: map-get($grid-breakpoints, md)) {
    justify-content: flex-start;
  }
  a {
    display: inline-flex;
  }
  cursor: pointer;

  .iconConatiner {
    height: 4em;
    width: 4em;
    max-width: 4em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $Secondary;
    margin-right: 1em;
    :hover {
    }
    i {
      display: flex;
    }
  }
  .textContainer {
    display: flex;
    max-height: 4em;
    align-items: center;
    font-weight: 800;
    @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 12px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 32px;
  }
}
