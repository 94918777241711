#photoGalery {
  background-color: aqua;
  .slick-prev {
    padding-left: 510px !important;
  }
}

.rotate3d {
  position: relative;
  transform: scale(1);
  cursor: pointer;
  z-index: 8;
  :first-child {
    :hover {
      z-index: 2;
      rotate: initial;
      box-shadow: initial;
      -webkit-box-shadow: initial;
      -moz-box-shadow: initial;
      transform: initial;
      ::before {
        content: none; /* Ocultar el pseudo-elemento en el primer hijo */
      }
    }
  }

  :hover {
    z-index: 10;
    transition: all 0.5s ease;
    transform: perspective(30em) rotateX(8deg);
    box-shadow: rgba(22, 31, 39, 0.42) 0px 5px 30px -5px;
  }
}

.carouselContainer {
  width: calc(100% + 2em);
  margin: 0 -1em;
}
.cardContainer {
  padding: 1em;
  padding-bottom: 2em;
}

.imgContainer {
  width: 100%; /* Ancho deseado del div */
  height: 200px; /* Altura deseada del div */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}
