@import "../../scss/_variables";

.publicacion {
  height: 20vh;
}
.imagenPublicacion {
  border: 1px solid black;
  cursor: pointer;
  border-radius: $Round;
}

.tituloPublicacion {
  margin-bottom: 0.6em;
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 20px;
  }
}

.buyButton {
  padding: 12px;
}
