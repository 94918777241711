@import "../../scss/variables";

.main {
  background-color: whitesmoke;
  padding: 2em;
  border-radius: $Round;
  min-height: 49vh;
}

.calculador {
  margin-bottom: 2em;
  ul {
    padding-left: 1em !important; // Ajustar el padding izquierdo para compensar la eliminación del estilo de lista
  }
  li {
    list-style: none;
    border: 2px solid $Primary;
    border-radius: 10px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 0.5em;
  }
}

.funcionInfo {
  position: relative;
  overflow: hidden;
}

.best {
  border: 2px solid $Secondary !important;
  border-radius: 10px;
  padding: 1em;
  margin-top: 3em !important;
  margin-bottom: 2em !important;
  margin-left: -4em;
  margin-right: -4em;
  box-shadow: 0 0 20px rgba($Secondary, 0.5);
  position: relative;
  padding-top: 2em !important;
  z-index: 5;
  h5 {
    background-color: $Secondary;
    border-radius: 10px;
    position: absolute;
    line-height: 2em;
    padding-left: 2em;
    padding-right: 2em;
    left: 50%;
    top: 0px;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
}

.capitularConatiner {
  padding: 0px;
}

.capitular {
  font-size: 32px;
  text-align: right;
}

.precioAlumno {
  font-size: 32px;
}

.tachado {
  text-decoration: line-through;
}

.nota {
  font-size: 12px;
  color: $Primary-darker;
}

.promoFlag {
    position: relative;
    flex-direction: column;
    margin-left: -3%;
    margin-right: -3%;
    display: flex;
    min-width: 106%;
    background-color: $Secondary;
    color: white;
    justify-content: center; // Centrar horizontalmente
    align-items: center; // Centrar verticalmente
    height: 100%; // Asegurar que el contenedor tenga altura suficiente
    box-shadow: 0 0 20px rgba($Secondary, 0.5);
    padding: 1em;
    p{
        font-weight: bold;
        align-self: center;
        margin-bottom: 0px;
        span{
            font-style: italic;
            font-size: 1.3em;
        }
    }
    ::after{
        content: "";
        position: absolute;
        width: 0;
        height: 15%;
        border-left: 3% solid transparent;
        border-right: 22px solid transparent;
        border-top: 22px solid $Secondary-dark;
        bottom: 0;
        right: 0;
        transform: rotate(calc(45deg*8))
                    translate(-10%, 100%);
    }
    ::before{
        content: "";
        position: absolute;
        width: 0;
        height: 15%;
        border-left: 5% solid transparent;
        border-right: 22px solid transparent;
        border-top: 22px solid $Secondary-dark;
        bottom: 0;
        left: 0;
        transform: rotate(calc(45deg*2))
                  translate(100%, -10%);
        z-index: 1
    }
}

// Media query using $grid-breakpoints
@media (max-width: map-get($grid-breakpoints, md)) {
  .best {
    margin-left: 0px;
    margin-right: 0px;
    h5 {
      font-size: 15px !important;
      width: 80%;
    }
  }
  .calculador {
    text-align: center;
  }

  .capitularConatiner {
    position: relative;
  }
  .capitular {
    margin-bottom: 0px;
    font-size: 30px;
    position: relative;
    text-align: center;
  }
  .nota {
    text-align: left;
  }
}
