@import "../../scss/variables.scss";

$pictureHeigth: 30vh;

.contenidoCartelra {
  min-height: 30vh;
  background-color: aqua;
}

a {
  text-decoration: none !important;
}

.funcionAgotada {
  position: absolute;
  padding: 0px;
  margin: 0px;
  z-index: 10;

  img {
    width: 80%;
    height: 80%;
  }
}

.overlay {
  background-color: rgba($color: white, $alpha: 0.6);
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}

.sinObras {
}
.imgConatiner {
  position: relative;
  padding: 0px;
  img {
    object-fit: cover;
    padding: 0px;
  }
  .overLay {
    position: absolute;
    top: 35%;
    text-align: center;
  }
}
