@import "../../scss/_variables";

.sliderRow {
  background-color: $PrimaryLgt03;
  padding: 1em 0 1em 0;
  border-style: solid;
  border-width: 0 0 4px 0;
  border-color: $Secondary;
}

.title {
  font-family: "DM Serif Display", serif;
  text-align: center;
  color: $Primary;
  font-style: italic;
}
