@import "../../scss/_variables";

.obra {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease;
  z-index: 2;
  cursor: pointer;
  :first-child {
    :hover {
      rotate: initial;
      box-shadow: initial;
      -webkit-box-shadow: initial;
      -moz-box-shadow: initial;
      transform: initial;
    }
  }

  :hover {
    transition: all 0.5s ease;
    transform: perspective(30em) rotateX(5deg);
    box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
  }
}

.obrasPie {
  text-transform: uppercase;
  font-size: 12px;
  color: $Primary-dark;
  background-color: $Primary-ligther;
}

.obraTitle {
  color: $Secondary;
  margin-bottom: -10px;
}
