@import "../../scss/_variables";
nav {
  margin: 0px !important;
  padding: 0px !important;
}

#obras {
  color: $Primary !important;
  font-weight: 800;
}

.dropdown {
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    width: 50vw;
    a {
      max-width: 50vw;
    }
  }
  a {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.dropdown-menu {
  border: 0px;
  margin-left: 2em;
  line-height: 3em;
  padding-left: 2em;
  padding-bottom: 1em;
  padding-right: 2em;
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    line-height: 40px;
    padding-left: 0px;
    width: 65vw;
  }
}

.dropdown-item.active {
  padding-left: 0px;

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    background-color: aqua;
  }
}
.dropdown-item {
  padding-left: 0px !important;
  a {
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: $Primary;
  font-weight: 800;
}

.offcanvas-header {
  background: $PrimaryGradient;
  border-bottom: 3px solid $Secondary;
  svg {
    margin-top: -1em !important;
    margin-left: -10px;
  }
}

.offcanvas {
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    width: 90vw;
  }
}
