@import "../../scss/variables";

.heroText01 {
  background: $SecondaryGradient;
  margin: 0px;
  padding: 0px;
  display: flex;

  img {
    height: 27vh;
    width: auto;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: 15vh;
      width: auto;
    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      height: 15vh;
      width: auto;
    }
  }
}

.heroTextWarper {
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: map-get($grid-breakpoints, md)) {
    justify-content: center;
    height: 100%;
  }
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    min-height: 60vh;
    .textContainer {
      h1{
        text-shadow: rgba($color: #000000, $alpha: 0.5) 0.1em 0.1em 0.1em;
      }
      h2 {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 0.1em;
        text-shadow: rgba($color: #000000, $alpha: 0.5) 0.1em 0.1em 0.1em;
      }
      p {
        font-size: 18px;
        text-shadow: rgba($color: #000000, $alpha: 0.5) 0.1em 0.1em 0.1em;
      }
    }
  }
}

.imageContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: 0px;
  position: absolute;

  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
    img {
      width: 50%;
      height: auto;
    }
  }

  @media (min-width: map-get($grid-breakpoints, full)) and (max-width: map-get($grid-breakpoints, 4k)) {
    img {
      width: 70%;
      height: auto;
    }
  }
}

.leftBox {
  display: flex;
  text-align: left;
  @media (max-width: map-get($grid-breakpoints, md)) {
    display: flex;
    bottom: 0px;
    text-align: center !important;
    img {
      width: 80%;
      height: auto;
    }
  }
}
.rigthBox {
  text-align: right;
  display: flex;
  @media (max-width: map-get($grid-breakpoints, md)) {
    display: flex;
    bottom: 0px;
    text-align: center !important;
    img {
      width: 80%;
      height: auto;
    }
  }
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  text-align: center;
  align-content: center;

}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 2em 10% 0 10%;

  h1{
    color: whitesmoke;
  }

  h2 {
    color: whitesmoke;
    font-style: normal;
    font-size: 45px;
    line-height: 40px;
    font-weight: 700;
    font-family: "Commissioner", sans-serif;
  }
  p {
    padding-top: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: whitesmoke;
  }
}

.logoContainer {
  border: 1px solid black;
  height: unset;
  img {
    margin-top: -20%;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}

.creadoresSection {
  .creadores {
    border-radius: $Round;
    overflow: hidden;
    background-color: lightgrey;
    .imgCreadores {
      background-color: $Primary;
      background: $PrimaryGradient;
    }
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin: 8px;
  }
}

.textCreadores {
  z-index: 10;
  margin-left: -15%;
  background-color: whitesmoke;
  padding: 2em;
  margin-right: 15%;
  border: 1px solid $Primary;
  border-radius: $Round;
  h2 {
    font-size: 28px;
  }

  line-height: 44px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin: -3em 1em 1em 1em;

    h2 {
      font-size: 18px;
    }
  }
}

.funcionesSection {
  .funciones {
    border-radius: $Round;
    overflow: hidden;
    background-color: lightgrey;
    height: unset;
    color: $Primary;
    .textFunciones {
      z-index: 10;
      margin-left: 15%;
      background-color: whitesmoke;
      padding: 2em;
      margin-right: -15%;
      border: 1px solid $Primary;
      border-radius: $Round;
      h2 {
        font-size: 28px;
      }
      p{
        font-size: 20px;
      }
      span {
        margin: 4em 0 4em 0em !important;
        color: $PrimaryLgt01;
        font-size: 20px;
        line-height: 10px;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin: -3em 1em 1em 1em;
        h2 {
          font-size: 18px;
        }
        p{
          
          font-size: 18px;
        }
        span{
          font-size: 18px;

        }
      }
    }
    .imgFunciones {
      background-color: $Primary;
      background: $PrimaryGradient;
      z-index: 1;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      height: unset;
    }
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin: 8px;
  }
}
