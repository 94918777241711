$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  full: 1919px,
  4k: 2560px,
);

$Round: 8px;

$lightness: 30%;

$Secondary: #ff8900;
$Secondary-ligth: #ffbb00;
// $Secondary: #FFBB00;
// $Secondary-ligth: #FF8900;
$Secondary-ligther: #ffdd83;
$Secondary-darker: #333029;
$Secondary-dark: #99813d;
$Primary: #5f9ea1;
$Primary-dark: #426b6c;
$Primary-darker: #153133;
$Primary-ligther: #b2dfe1;
$Primary-ligth: #b0f3ff;

$PrimaryLgt01: scale-color($Primary, $lightness: 20%);
$PrimaryLgt02: scale-color($Primary, $lightness: 50%);
$PrimaryLgt03: scale-color($Primary, $lightness: 80%);

$SecondaryLgt01: scale-color($Secondary, $lightness: 20%);
$SecondaryLgt02: scale-color($Secondary, $lightness: 50%);
$SecondaryLgt03: scale-color($Secondary, $lightness: 80%);

$SecondaryGradient: linear-gradient(
  45deg,
  #ff8900 0%,
  #ffbb00 85%,
  #ff8900 100%
);
$PrimaryGradient: linear-gradient(
  45deg,
  $Primary-dark 0%,
  $Primary 35%,
  $Primary 100%
);

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #{$Primary};
  --bs-btn-border-color: #{$Primary};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{$Primary-dark};
  --bs-btn-hover-border-color: #{$Primary-dark};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{$Primary-dark};
  --bs-btn-active-border-color: #{$Primary-dark};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #{$Primary-ligth};
  --bs-btn-disabled-border-color: #{$Primary-ligth};
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #{$Secondary};
  --bs-btn-border-color: #{$Secondary};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{$Secondary-dark};
  --bs-btn-hover-border-color: #{$Secondary-dark};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{$Secondary-dark};
  --bs-btn-active-border-color: #{$Secondary-dark};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #{$Primary-ligth};
  --bs-btn-disabled-border-color: #{$Secondary-ligth};
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: $Primary-ligther;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border-radius: $Round !important;
  margin-bottom: 0.5em;
  color: $Primary-dark !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: $Secondary-ligth;
  // background: $SecondaryGradient;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.slick-dots {
  bottom: -7px !important;
}

.btn-close {
  --bs-btn-close-bg: url("../assets/svg/closeButton.svg");
}

// querys

// @media
//     (min-width: map-get($grid-breakpoints, xs)) and
//     (max-width: map-get($grid-breakpoints, sm))
// {
//     font-size: 16px;
// }
