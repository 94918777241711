@import "../../scss/variables.scss";
$pictureHeigth: 30vh;
.obra {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease;
  z-index: 2;

  :first-child {
    :hover {
      rotate: initial;
      box-shadow: initial;
      -webkit-box-shadow: initial;
      -moz-box-shadow: initial;
      transform: initial;
    }
  }

  :hover {
    transition: all 0.5s ease;
    transform: perspective(30em) rotateX(5deg);
    box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
  }

  h4 {
    position: absolute;
    bottom: 0%;
    text-align: right;
    right: 0px;
    background-color: rgba($color: $Secondary, $alpha: 0.7);
    padding: 0.6em;
    color: white !important;
    text-shadow: 1px 1px 2px black;
    small {
      font-size: 0.6em;
      color: $Primary-darker;
      text-transform: uppercase;
      background-color: $Primary-ligther;
      padding: 0.2em 1em 0.2em 1em;
      margin: 0;
      border-radius: $Round;
      text-shadow: none;
    }
  }
  ul {
    list-style: none;
    li {
      font-weight: 800;
      span {
        font-weight: 400;
      }
    }
  }
}

.imgContainer {
  position: relative;
  height: $pictureHeigth;
  img {
    object-fit: cover;
    height: $pictureHeigth;
    object-position: top;
  }
}

.obrasPie {
  text-transform: uppercase;
  font-size: 14px;
  color: $Primary-darker;
  background-color: $Primary-ligther;
  font-weight: 600;
}

.esEstreno {
  background-color: red !important;
  color: white;
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  padding-top: 1em;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  b {
    pointer-events: none;
  }
  &:hover {
    pointer-events: none;
    b {
      pointer-events: none;
    }
  }
}
