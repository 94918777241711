@import "../../scss/variables";

.yearContainer {
  background: $SecondaryGradient;
  border-radius: $Round;
  position: sticky;
  padding: 1em 2em 1em 2em;
  top: 100px;
  z-index: 500;
  color: whitesmoke;
  font-weight: 600;
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 14px !important;
  }
}

.yearEvents {
  margin-top: 3em;
  margin-bottom: 3em;
  scroll-behavior: smooth;
  background: $PrimaryGradient;
  padding: 3em;
  border-radius: $Round;
}

.year {
  margin: 2em 0 0 0;
  padding: 2em;
  background-color: whitesmoke;
  border-radius: $Round;
  h4 {
    color: $Secondary;
  }
}

.yearItem {
  a {
    font-weight: 600;
    color: $Primary;
  }
}

.slickActive {
  background-color: #f0f0f0; /* Cambia el color de fondo según tu preferencia */
}
