@import "../../scss/variables";

.recursos {
  min-height: 60vh;
  padding-bottom: 3em;
  background-color: white;
  border-radius: $Round;
  padding: 1em;
  margin-bottom: 4em;
  box-shadow: 0px 0px 20px 0px rgba($color: #000000, $alpha: 0.3);

  ul {
    padding: 0px;
  }
  li {
    list-style: none;
  }
  a {
    margin-left: 1em;
  }

  // Breakpoint para sm y mayores
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding: 2em 2em 2em 2em;
  }
}

.recurosHeader {
  h2 {
    z-index: 12;
  }
}

.filterBarContainer {
  padding: 0px; // Estilo base para todos los tamaños
  padding-right: 32px; // Específico para xs y menores

  // Cambia el padding-right para tamaños 'sm' en adelante
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding-right: 0px;
  }
}

.filterBar {
  margin: 0px;
  padding-top: 100px !important;
  margin-top: -150px !important;
  // background-color: white;
  z-index: 15;

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    padding-top: 99px !important;
    margin-top: -150px !important;
    margin-right: -32px !important;
    margin-left: -33px !important;
    padding-left: 32px !important;
    transform: translateY(0px);
  }
}

.filterBarPositionClose {
  transform: translateY(-330px) !important;
}

.filterContainer {
  text-align: left;
  padding: 10px 10px 10px 0;
  margin: 0px;
  @media (min-width: map-get($grid-breakpoints, xs)) {
    padding: 5px 0 0 0;
    margin-left: 12px;
  }
}

.dropDownContainer {
  background-color: $PrimaryLgt03;
  border-radius: $Round;
  border: 1px solid $Primary;
}
.dropButton {
  padding: 0px;
}

.filterToggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid red;
  height: 50px;
  svg {
    margin-left: 0.5em;
  }
}
