@import "../../scss/_variables";

$photoSize: 150px;

.notaDePrensa {
  margin-top: calc($photoSize / 1.5);
  img {
    width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
    height: 100%; /* Asegura que la imagen ocupe todo el alto del contenedor */
    object-fit: cover; /* Ajusta la imagen para que cubra todo el contenedor */
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    color: $Primary-darker;
    font-size: 14px;
    li {
      margin-top: 5px;
    }
  }
  button {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.notaCard {
}
.imgContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.imgBox {
  position: absolute;
  top: calc(($photoSize / 2) * -1);
  border-radius: 50%; /* Hace que el contenedor de la imagen sea circular */
  overflow: hidden; /* Oculta cualquier parte de la imagen que se desborde */
  min-width: $photoSize;
  max-width: $photoSize; /* Establece el ancho máximo del contenedor */
  height: $photoSize; /* Establece la altura del contenedor */
  display: flex; /* Permite centrar verticalmente la imagen */
  justify-content: center; /* Centra horizontalmente la imagen */
  align-items: center; /* Centra verticalmente la imagen */
  background-color: whitesmoke;
  box-shadow: 3px 3px 21px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 3px 21px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 21px -7px rgba(0, 0, 0, 0.75);
}

.titleNota {
  color: $Primary;
  margin-top: calc($photoSize/1.8);
  min-height: 60px;
}
