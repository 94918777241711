@import "./scss/_variables";

body {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
  color: $Primary-darker;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
