.modalContent {
  img {
    display: flex;
    margin: auto;
    width: 100%;
    border-radius: 8px 8px 0 0px;
  }
}

.modalBody {
  justify-content: center;
}
