@import "../../scss/_variables";

.mainNavBar {
  height: 100px;
}

.customNavLink {
  display: flex;
  align-items: center;
  margin-right: 1.3em;

  :hover {
    color: $Primary;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 500;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 0.7em !important;
    }
    @media (max-width: map-get($grid-breakpoints, full)) {
      font-size: 0.9em;
    }
  }
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 28px;
    line-height: 40px;
  }
}

.navActive {
  color: $Primary !important;
  font-weight: 800 !important;
}
.navLink {
  display: flex;
  justify-content: flex-end;
}

.customBrand {
  margin-left: 10px;
  height: 80px;
  svg {
    width: 250px;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: unset;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: unset;
    }
  }
}

.dropDown {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 80vh;
}

.subMenuContainer {
  background-color: $PrimaryLgt03;
  margin: 0px -20px 0px -20px;
  padding: 0px 20px 0px 0px;
}

.subMenu {
  padding-left: 2.5em !important;
  padding-right: 1em;
  width: 100%;
  padding-right: 10px;
  margin-right: 30px;
  margin-left: 0px;
  position: relative;
  &:hover {
    &::after {
      content: "";
      position: absolute;
      right: -20px;
      height: 100%;
      width: 10px;
      background: $PrimaryGradient;
    }
  }
}

@media (max-width: 575.98px) {
  .customOffCanvas {
    flex-direction: column;
  }
}
