/* The following line can be included in a src/App.scss */
@import "~bootstrap/scss/bootstrap";
@import "../src/scss/variables";

.App {
  padding: 0px !important;
  margin: 0px !important;
}

#homeHeroSlider {
  .slick-next {
    height: 100%;
    min-width: 10vw;
    text-align: right;
    padding: 50px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
  .slick-prev {
    padding-left: 50px;
    z-index: 50;
    height: 100%;
    min-width: 10vw;
    text-align: left;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    background: -webkit-linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
}

#photoGalery {
  .slick-prev {
    z-index: 101;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding-top: 1.1px;
    margin-left: 50px;
  }
  .slick-next {
    padding-top: 1.1px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    margin-right: 50px;
  }
}
