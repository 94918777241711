@import "../../scss/_variables";

.obraHeader {
  background: $SecondaryGradient;
  color: white;
  padding: 2em 0 0 0;
}

.obraInfo {
  // Media query for screen sizes between extra small (xs) and small (sm)
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    ul {
      button {
        margin-top: 1em;
      }
    }
    .calculadorCTA {
      position: absolute;
      height: 120%;
      right: 0.5em;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  // Media query for screen sizes between small (sm) and medium (md)
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {

    // .calculadorCTA {
    //   top: unset;
    //   bottom: 10%;
    // }
  }

}

.tipoDeObra {
  color: $Secondary;
}

.portadaCTAContainer{
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
  right: 0px;
  height: 20%;
  // border: 1px solid red;
  bottom: 0px;
  align-items: center;
  justify-content:flex-end;
  align-content: center;
  display: flex;
  flex-direction: row-reverse;
  text-align: right;


  .calculadorCTA {
    cursor: pointer;
    height: 100%;
    width: 50%;
    margin-top: -1em;
    background-color: transparent;
    transition: all 0.5s ease; // Asegurar que la transición esté en el estado base
    :hover{
      transition: all 0.3s ease; // Asegurar que la transición esté en el estado base
      transform: scale(1.1);
      
    }
    :nth-child(2){
    pointer-events: none;
    }

  }

  .linkTrigger {
    position: absolute;
    cursor: pointer;
    height: 100%;
    width: 50%;
    background-color: transparent;
    border: 1px solid $Secondary-dark;
    z-index: 50;
  }
}

.framne {
  background-color: cadetblue;
  position: relative;
  display: flex;
  min-height: 250px;
  margin-bottom: 2em;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  svg {
    height: 20%;
    color: whitesmoke;
    pointer-events: none;
  }
}

.tituloAcorion {
  span {
    font-size: 24px !important;
    margin-bottom: 0px !important;
    font-weight: 500;
  }
}

.dataText {
  padding: 2em;
  height: 100%;
  background: whitesmoke;
  position: absolute;
  width: 100%;
  transition: transform 0.2s ease-out;
  transition: all 0.5s ease;
  z-index: 10;
  hr {
    margin-top: 1em;
  }
  ul {
    pointer-events: none;
    li {
      pointer-events: none;
      display: inline-block;
      padding-right: 1em;
      padding-left: 1em;
      border-style: solid;
      border-color: darkgray;
      border-radius: 8px;
      margin-right: 1em;
      border-width: 2px;
      font-weight: 600;
      color: darkgray;
    }
  }
  div {
    pointer-events: none;
  }
  :first-child {
    pointer-events: none;
  }
  :last-child {
    pointer-events: none;
  }
}

.iconDownload {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-content: center;
  pointer-events: none;
  h5 {
    color: whitesmoke;
    text-align: center;
    padding: 0px !important;
    margin-left: 1em;
  }
}

.dataText:hover {
  transform: translateY(140%);
}

.iconDownload:hover {
  transform: translateY(-100%);
}

.pointerEventNone {
  pointer-events: none;
}

.tituloCiclos {
  padding: 0px !important;
}

.temas {
  font-style: italic;
  font-weight: 600;
  background-color: $SecondaryLgt03;
  border-radius: $Round;
  border: 2px solid $Secondary;
  padding: 1em 2em 1em 3em;
}

.premios {
  font-weight: 800;
  list-style: none;
  font-style: italic;
  li {
    padding: 0em;
  }
}

.obraPie {
  display: flex;
  background-color: $Primary-ligther;
  align-items: center;
  padding: 1em;
  border: 2px solid $Primary;
  border-radius: $Round;
  p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: $Primary-darker;
    margin: 0;
  }
}


