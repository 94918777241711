@import "../../scss/variables";

.obrasPorMes {
  ul {
    padding: 0px;
  }
  li {
    list-style: none;
  }
}

.funcionAgotada {
  position: absolute;
  padding: 0px;
  margin: 0px;
  z-index: 10;
  top: 0px;
  img {
    width: 40%;
    height: 40%;
  }
}

.funcion {

  margin-bottom: 2em;
  background-color: whitesmoke;
  padding: 2em;
  border-radius: $Round;

  h4 {
    color: $Secondary !important;
    small{
      color: $Primary-dark;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    background: whitesmoke;
  }
}

.obrasRow {
  h3 {
    margin-bottom: 1em;
  }
  background: linear-gradient(45deg, $PrimaryLgt03 0%, #ffffff 40%);
  padding: 2em;
  border-radius: $Round;

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    padding: 0px !important;
    ul {
      margin-left: 24px;
      button {
        margin-top: 2em;
      }
    }
    li {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0.5em;
      strong {
        font-size: 16px;
      }
    }
  }
}

.promo {
  border: 2px solid $Secondary !important;
  box-shadow: 0 0 20px rgba($Secondary, 0.5);
}

.promoName {

  width: 70%;
  min-height: 2em;
  margin: auto;
  background-color: $Secondary;
  text-align: center;
  margin-top: -3em;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  display: flex;
  max-height: 2em;

}

.promoDescription{
  margin-top: 1em;
  position: relative;
  display: flex;
  justify-content: center;
  color: $Secondary;
  font-weight: 700;
  pointer-events: none;

}

// La clase .querys aplica estilos específicos para pantallas con un ancho mínimo 
// correspondiente al valor de 'lg' y un ancho máximo correspondiente al valor de 'xl' 
// según los puntos de quiebre definidos en $grid-breakpoints.

  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  }
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    .promoName{
      width: 100%;
      padding: 0.5em;
      max-height: fit-content;
      margin-bottom: 1em;
    }
  }

