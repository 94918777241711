@import "../../scss/_variables";

.cardRow {
  background: linear-gradient(45deg, #ff8900 0%, #ffbb00 85%, #ff8900 100%);
  h3 {
    color: $SecondaryLgt03 !important;
    font-style: italic;
    font-size: 48px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 35px;
    }
  }
}

.card {
  background-color: whitesmoke;
  min-height: 15vh;
  border-radius: $Round;
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;

  h4 {
    color: $Secondary !important;
    font-size: 55px;
    font-style: italic;
    font-family: "DM Serif Display", serif;
  }
  h6 {
    font-size: 20px;
    font-family: "DM Serif Display", serif;
    font-style: italic;
  }
}
