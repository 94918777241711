@import "../../scss/variables";

.contacUs {
  background-color: whitesmoke;
  padding: 2em;
  border-radius: $Round;

  label,
  input,
  textarea {
    font-size: 12px;
  }

  button {
    width: 100%;
  }
}
